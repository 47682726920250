import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import axiosInstance from '../../../utils/axios';
import { IErrorResponse } from '../../querys/querys.enum';
import { MutationKeys } from '../../mutation/mutation.enum';

type OneTimeEmailReqVariables = {
    timeToReceive: `${string}${'AM' | 'PM'}`
    newsletter: boolean | undefined,
    subscribeToLearningEmails: boolean | undefined,
    premium: boolean | undefined,
    emailType: 'marketing' | 'feature'
}

const useOneTimeEmailReq = async (data: OneTimeEmailReqVariables) => {
    const response = await axiosInstance.post<Partial<IErrorResponse>>(
        `emails/one-time`,
        {
            ...data,
        }
    );

    return response.data;
};

const useOneTimeEmail = (
    options?: Partial<
        UseMutationOptions<
            Partial<IErrorResponse>,
            AxiosError<IErrorResponse>,
            OneTimeEmailReqVariables
        >
    >
) => {
    return useMutation({
        mutationKey: [MutationKeys.UPDATE_B2B_SUBSCRIPTION],
        mutationFn: async (data) => {
            return await useOneTimeEmailReq(data);
        },
        ...options,
    });
};
export default useOneTimeEmail;
