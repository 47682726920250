import React from "react";
import TextInput from "../../../commons/TextInput/TextInput";
import TranslationManager from "../components/TranslationManager";
import { languagesList } from "../../../utils/languages/languagesList";

const LandingTranslations = () => {
  const [search, setSearch] = React.useState("");

  const [selectedLanguages, setSelectedLanguages] = React.useState<string[]>(
    []
  );

  const handleLanguageSelection = (language: string) => {
    if (selectedLanguages.includes(language)) {
      setSelectedLanguages(
        selectedLanguages.filter((lang) => lang !== language)
      );
    } else {
      setSelectedLanguages([...selectedLanguages, language]);
    }
  };

  return (
    <div className="flex px-30 pt-55 pb-20 flex-col bg-light-grey-1 h-full max-w-1200">
      <h2 className="text-dark-grey font-bold text-2xl mb-30">
        Landing Translations
      </h2>
      <div className="flex justify-between items-center mb-30  ">
        <TextInput
          customInputStyles=" w-full h-30"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          value={search}
          placeholder="Containing"
        />
        <ul className="flex justify-center mb-30 flex-wrap">
          {languagesList.map((language) => (
            <li
              key={language}
              className={`px-4 py-2 cursor-pointer ${
                selectedLanguages.includes(language) ? "text-primary" : ""
              }`}
              onClick={() => handleLanguageSelection(language)}
            >
              {language}
            </li>
          ))}
        </ul>
      </div>

      <div className="overflow-x-auto rounded-xl border-light-grey-3 border-solid border flex-row flex flex-1 overscroll-scroll ">
        <TranslationManager type={"landing"} language="en" search={search} />
        {selectedLanguages.map((language) => (
          <TranslationManager
            key={language}
            type="landing"
            language={language}
            search={search}
          />
        ))}
      </div>
    </div>
  );
};

export default LandingTranslations;
