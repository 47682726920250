import { useQueryClient } from "@tanstack/react-query";
import Logo from "../../assets/logo.png";
import NavbarLink from "./NavbarLinks/NavbarLink";
import useLogout from "../../state/hooks/auth/useLogout";
import Button from "../Button/Button";

function Navbar() {
  const { mutate: logout, isPending } = useLogout();

  const logOut = () => {
    logout();
  };

  return (
    <div className="h-full min-w-186 bg-white flex flex-col py-3 justify-between flex-wrap">
      <div>
        <div className="ml-20 mb-20 flex items-center gap-4">
          <img src={Logo} alt="logo" className="w-44" />
          <div className="text-20 font-extrabold">Talkpal</div>
        </div>

        <div>
          <NavbarLink linkTo="/main-users">Users</NavbarLink>
          <NavbarLink linkTo="/sub-users">Sub users</NavbarLink>
          <NavbarLink linkTo="/b2b-admins">B2B Admin Users</NavbarLink>
          <NavbarLink linkTo="/organizations">B2B Organizations</NavbarLink>
          <NavbarLink linkTo="/subscriptions">Subscriptions</NavbarLink>
          <NavbarLink linkTo="/b2b-subscriptions">B2B Subscriptions</NavbarLink>
          <NavbarLink linkTo="/transactions">Transactions</NavbarLink>
          <NavbarLink linkTo="/translations">App - Translations</NavbarLink>
          <NavbarLink linkTo="/one-time-email">One time email</NavbarLink>
          <NavbarLink linkTo="/email-translations">
            Email - Translations
          </NavbarLink>
          <NavbarLink linkTo="/notification-translations">
            Notifications - Translations
          </NavbarLink>
          <NavbarLink linkTo="/landing-translations">
            Landing - Translations
          </NavbarLink>
          <NavbarLink linkTo="/prompts">Prompts</NavbarLink>
          <NavbarLink linkTo="/exports">Exports</NavbarLink>
        </div>
      </div>
      <div className="border-t-2 border-t-light-grey-1 border-solid w-full flex justify-center pt-30 pb-80">
        <Button
          onClick={logOut}
          disabled={isPending}
          className="text-sm w-full font-semibold"
          type={isPending ? "secondary" : "main"}
        >
          {"Logout"}
        </Button>
      </div>
    </div>
  );
}

export default Navbar;
