import { QueryClientProvider } from "@tanstack/react-query";
import React, { Suspense, useEffect } from "react";
import { queryClient } from "../state";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "../screens/Login/Login";
import NotFound from "../screens/NotFound/NotFound";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import WithPermission from "./Permission";
import SubUsers from "../screens/SubUsers/SubUsers";
import Users from "../screens/Users/Users";
import Subscriptions from "../screens/Subscriptions/Subscriptions";
import Exports from "../screens/Export/Exports";
import Transactions from "../screens/Transactions/Transactions";
import Prompts from "../screens/Prompts/Prompts";
import SinglePrompt from "../screens/Prompts/SinglePrompt";
import SubProfile from "../screens/Profile/SubProfile";
import AddNewPrompt from "../screens/Prompts/AddNewPrompt";
import EditSubscription from "../screens/EditSubscription/EditSubscription";
import NewSubscription from "../screens/NewSubscriptions/NewSubscriptions";
import EditTransaction from "../screens/EditTransaction/EditTransaction";
import Profile from "../screens/Profile/Profile";
import Translations from "../screens/Translations/Translation";
import Organizations from "../screens/Organizations/Organizations";
import B2BAdmins from "../screens/B2BAdmins/B2BAdmins";
import B2BSubscriptions from "../screens/B2BSubscriptions/B2BSubscriptions";
import B2BAdminProfile from "../screens/B2BAdmins/B2BAdmin";
import Organization from "../screens/Organizations/Organization";
import B2BSubscription from "../screens/B2BSubscriptions/B2BSubscription";
import EmailTranslations from "../screens/Translations/email";
import NotificationsTranslation from "../screens/Translations/notifications";
import OneTimeEmail from "../screens/OneTimeEmail/OneTimeEmail";
import LandingTranslations from "../screens/Translations/landing";

const routes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    element: <WithPermission />,
    children: [
      {
        path: "/main-users",
        element: <Users />,
      },
      {
        path: "/sub-users",
        element: <SubUsers />,
      },
      {
        path: "/b2b-admins",
        element: <B2BAdmins />,
      },
      {
        path: "/organizations",
        element: <Organizations />,
      },
      {
        path: "/organizations/:orgId",
        element: <Organization />,
      },
      {
        path: "/subscriptions",
        element: <Subscriptions />,
      },
      {
        path: "/b2b-subscriptions",
        element: <B2BSubscriptions />,
      },
      {
        path: "/b2b-subscriptions/:id",
        element: <B2BSubscription />,
      },
      {
        path: "/translations",
        element: <Translations />,
      },
      {
        path: "/email-translations",
        element: <EmailTranslations />,
      },
      {
        path: "/notification-translations",
        element: <NotificationsTranslation />,
      },
      {
        path: "/landing-translations",
        element: <LandingTranslations />,
      },
      {
        path: "/subscriptions/:id",
        element: <EditSubscription />,
      },
      {
        path: "/new-subscription",
        element: <NewSubscription />,
      },
      {
        path: "/transactions",
        element: <Transactions />,
      },
      {
        path: "/transactions/:id",
        element: <EditTransaction />,
      },
      {
        path: "/prompts",
        element: <Prompts />,
      },
      {
        path: "/prompts/:promptType",
        element: <SinglePrompt />,
      },
      {
        path: "/new-prompt",
        element: <AddNewPrompt />,
      },
      {
        path: "/main-users/:id",
        element: <Profile />,
      },
      {
        path: "/sub-users/:id",
        element: <SubProfile />,
      },
      {
        path: "/b2b-admins/:id",
        element: <B2BAdminProfile />,
      },
      {
        path: "/exports",
        element: <Exports />,
      },
      {
        path: "/one-time-email",
        element: <OneTimeEmail />,
      },
      {
        path: "/*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const Wrappers = () => {
  const router = createBrowserRouter(routes, {
    basename: "/",
  });

  useEffect(() => {
    console.log(`%cStop!`, "color:red; font-size:70px;font-weight:bold");
    console.log(
      `%c
This is a browser feature intended for developers. If someone told you to copy-paste something here to enable a Talkpal feature or "hack" someone's account, it is a scam and will give them access to your Talkpal account`,
      `font-size:40px`
    );
  }, []);
  return (
    <Suspense fallback="Loading...">
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} fallbackElement={<Fallback />} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Suspense>
  );
};

export default Wrappers;

const Fallback = () => {
  window.location.href = "/";

  return <div>Loading...</div>;
};
