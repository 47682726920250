import useOneTimeEmail from "../../state/hooks/one-time-emails/useOneTimeEmail";
import { Formik } from "formik";
import Button from "../../commons/Button/Button";

type InitialValuesType = {
  hours: string;
  minutes: string;
  period: "AM" | "PM";
  newsletter: "yes" | "no" | "both";
  subscribeToLearningEmails: "yes" | "no" | "both";
  sendTo: "basic" | "premium" | "all";
  emailType: "marketing" | "feature";
};
const initialValue: InitialValuesType = {
  hours: "",
  minutes: "",
  period: "AM",
  newsletter: "no",
  subscribeToLearningEmails: "both",
  sendTo: "basic",
  emailType: "marketing",
};
const OneTimeEmail = () => {
  const { mutate: mutateAsync } = useOneTimeEmail();

  return (
    <div className="flex justify-center items-center h-screen">
      <Formik
        initialValues={initialValue}
        validate={(values) => {
          const errors: Record<string, string> = {};
          if (!values.hours) {
            errors.hours = "Required";
          } else {
            // hours must be a number between 1 and 12
            if (!/^(0?[1-9]|1[0-2])$/.test(values.hours)) {
              errors.hours = "Hours must be a number between 1 and 12";
            }
          }

          if (!values.minutes) {
            errors.minutes = "Required";
          } else {
            // minutes must be a number between 00 and 59
            if (!/^[0-5]?[0-9]$/.test(values.minutes)) {
              errors.minutes = "Minutes must be a number between 00 and 59";
            }
          }
          return errors;
        }}
        onSubmit={(values) => {
          const time = `${values.hours}:${values.minutes}`;
          const period = values.period as "AM" | "PM";
          const timeToReceive = `${time}${period}` as
            | `${string}AM`
            | `${string}PM`;
          const timeToReceiveRegex = /^(0?[1-9]|1[0-2]):[0-5]?[0-9](AM|PM)$/;
          if (timeToReceiveRegex.test(timeToReceive)) {
            const lastSubmitted = localStorage.getItem("lastSubmitted");
            const now = new Date().getTime();

            if (
              !lastSubmitted ||
              now - parseInt(lastSubmitted) > 2 * 60 * 1000
            ) {
              localStorage.setItem("lastSubmitted", now.toString());
              mutateAsync({
                timeToReceive: timeToReceive,
                subscribeToLearningEmails:
                  values.subscribeToLearningEmails === "both"
                    ? undefined
                    : values.subscribeToLearningEmails === "yes",
                newsletter:
                  values.newsletter === "both"
                    ? undefined
                    : values.newsletter === "yes",
                premium:
                  values.sendTo === "all"
                    ? undefined
                    : values.sendTo === "premium",
                emailType: values.emailType,
              });
            } else {
              alert("Please wait for 2 minutes before submitting again.");
            }
          }
        }}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          handleBlur,
          setFieldTouched,
          errors,
        }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="bg-white p-8 rounded shadow-md w-96"
            >
              <div className="mb-4">
                <label className="block">Time to receive</label>
                <input
                  type="text"
                  name="hours"
                  placeholder="Enter hours (HH)"
                  value={values.hours}
                  onChange={(e) => {
                    setFieldValue("hours", e.target.value);
                  }}
                  onBlur={handleBlur}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                {errors.hours && (
                  <div className="text-error text-sm mt-1">{errors.hours}</div>
                )}
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="minutes"
                  placeholder="Enter minutes (MM)"
                  value={values.minutes}
                  onChange={(e) => {
                    setFieldValue("minutes", e.target.value);
                  }}
                  onBlur={handleBlur}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                {errors.minutes && (
                  <div className="text-error text-sm mt-1">
                    {errors.minutes}
                  </div>
                )}
              </div>
              <div className="mb-4">
                <label className="block">Period</label>
                <select
                  value={values.period}
                  onChange={(e) => {
                    setFieldTouched("period", true);
                    setFieldValue("period", e.target.value);
                  }}
                  className="w-full p-2 border border-gray-300 rounded"
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block">Email Type</label>
                <select
                  value={values.emailType}
                  onChange={(e) => {
                    setFieldTouched("emailType", true);
                    setFieldValue("emailType", e.target.value);
                  }}
                  className="w-full p-2 border border-gray-300 rounded"
                >
                  <option value="marketing">marketing</option>
                  <option value="feature">feature</option>
                </select>
              </div>
              <div>
                <label>learning - (subscribeToLearningEmails)</label>
                <div className="mb-4 flex-row flex justify-around ">
                  <label className={"flex items-center"}>
                    <input
                      type="checkbox"
                      name="subscribeToLearningEmails"
                      checked={values.subscribeToLearningEmails === "yes"}
                      onChange={(e) => {
                        setFieldValue("subscribeToLearningEmails", "yes");
                      }}
                      className="mr-2"
                    />
                    YES
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="subscribeToLearningEmails"
                      checked={values.subscribeToLearningEmails === "no"}
                      onChange={(e) => {
                        setFieldValue("subscribeToLearningEmails", "no");
                      }}
                      className="mr-2"
                    />
                    No
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="subscribeToLearningEmails"
                      checked={values.subscribeToLearningEmails === "both"}
                      onChange={(e) => {
                        setFieldValue("subscribeToLearningEmails", "both");
                      }}
                      className="mr-2"
                    />
                    both
                  </label>
                </div>
              </div>
              <div>
                <label>marketing - (newsletter)</label>
                <div className="mb-4 flex-row flex justify-around ">
                  <label className={"flex items-center"}>
                    <input
                      type="checkbox"
                      name="newsletter"
                      checked={values.newsletter === "yes"}
                      onChange={(e) => {
                        setFieldValue("newsletter", "yes");
                      }}
                      className="mr-2"
                    />
                    YES
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="newsletter"
                      checked={values.newsletter === "no"}
                      onChange={(e) => {
                        setFieldValue("newsletter", "no");
                      }}
                      className="mr-2"
                    />
                    No
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="newsletter"
                      checked={values.newsletter === "both"}
                      onChange={(e) => {
                        setFieldValue("newsletter", "both");
                      }}
                      className="mr-2"
                    />
                    both
                  </label>
                </div>
              </div>
              <div>
                <label>Premium</label>
                <div className="mb-4 flex-row flex justify-around ">
                  <label className={"flex items-center"}>
                    <input
                      type="checkbox"
                      name="premium"
                      checked={values.sendTo === "premium"}
                      onChange={(e) => {
                        setFieldValue("sendTo", "premium");
                      }}
                      className="mr-2"
                    />
                    Premium
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="premium"
                      checked={values.sendTo === "basic"}
                      onChange={(e) => {
                        setFieldValue("sendTo", "basic");
                      }}
                      className="mr-2"
                    />
                    basic
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="premium"
                      checked={values.sendTo === "all"}
                      onChange={(e) => {
                        setFieldValue("sendTo", "all");
                      }}
                      className="mr-2"
                    />
                    all
                  </label>
                </div>
              </div>

              <Button buttonType="submit" type="main">
                Submit
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default OneTimeEmail;
